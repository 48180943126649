import "@fortawesome/fontawesome-svg-core/styles.css";
import { Link } from "gatsby";
import * as React from "react";
import { Container, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
// markup
export default function IndexPage({ data }) {
  const Hero = data.Hero.nodes;
  const BogoBenefits = data.BogoBenefits.nodes;
  const BogoServices = data.BogoServices.nodes;
  const Merchant = data.Merchant.nodes;

  return (
    <Layout>
      <div className="application">
        <Helmet>
          <meta
            name="_globalsign-domain-verification"
            content="54LhWIXAJ_c184oPxkUPKb-ZmqJ_z1xIesQVjGuiAZ"
          />
        </Helmet>
      </div>
      <main>
        <Container fluid={true}>
          <section className="mb-xl-5 mt-5">
            <Container>
              <Row>
                {Hero.map((node, index) => {
                  return (
                    <>
                      <div className="col-xl-4 col-lg-5 col-md-12">
                        <h1 className="fw-800 fs-48">{node.Title}</h1>
                        <h1 className="fw-800 fs-48 bogo-color mb-xl-5 mb-lg-5 mb-md-5 mb-2">
                          {node.SubTitle}
                        </h1>
                        <h5 className="mb-xl-5 mb-lg-5 mb-md-5 mb-2 py-4 fw-airlight">
                          {node.Description}
                        </h5>
                        <div className="d-flex mb-5 pb-xl-0 pb-lg-0 pb-md-0 pb-5">
                          <a
                            target="_blank"
                            href={node.AndroidCTALink}
                            className="nav-link ps-0 w-auto"
                          >
                            <img
                              src="../img/Googleplay.svg"
                              alt="Google Play"
                            />
                          </a>
                          <Link
                            to={node.IOSCTALink}
                            className="nav-link align-self-center"
                          >
                            <img src="../img/Playstore.svg" alt="Play Store" />
                          </Link>
                        </div>
                      </div>
                      <div className="col-xl-8 col-lg-7 col-md-12 text-end">
                        <img
                          src="../img/bg-home.png"
                          alt="background women"
                          className="women-bg"
                        />
                      </div>
                    </>
                  );
                })}
              </Row>
            </Container>
          </section>
        </Container>
        <Container>
          <section className="pt-xl-4 pt-lg-4 pt-md-4 pt-0 mb-5 home-second">
            <div className="col-xl-11 col-lg-12 col-md-12 mx-auto">
              <div className="row">
                {BogoBenefits.map((node, index) => {
                  return (
                    <>
                      {node?.BogoBenefits?.length > 0 &&
                        node.BogoBenefits.map((node2) => (
                          <div className="col-xl-4 col-lg-6 col-md-6 Card-second">
                            <img src={node2.BenefitsImage} alt="images" />
                            <h4 className="mt-5 mb-3 fs-22 fw-bold">
                              <Link
                                className="text-dark text-decoration-none fs-22"
                                to={node2.BenefitsCTALink}
                              >
                                {node2.BenefitsTitle}
                              </Link>
                            </h4>
                            <p className="fs-18 bogo-par pe-3 fw-normal lh-27 fw-airlight">
                              {node2.BenefitsDescription}
                            </p>
                          </div>
                        ))}
                    </>
                  );
                })}
              </div>
            </div>
          </section>
          <section className="pt-xl-4 pt-lg-4 pt-md-4 pt-0 pb-xl-0 pb-lg-0 pb-md-0 pb-5 mb-5 home-third">
            <div className="col-xl-11 col-lg-12 col-md-12 mx-auto ">
              <Row className="Card-third">
                {BogoServices.map((node, index) => {
                  return (
                    <>
                      {node?.BogoServices?.length > 0 &&
                        node.BogoServices.map((node2) => (
                          <a
                            href={node2.ServicesLink}
                            className="nav-link first-child  w-auto"
                            target="_blank"
                          >
                            <img
                              src={node2.ServicesImage}
                              alt={node2.ServicesTitle}
                            />
                            <p>{node2.ServicesTitle}</p>
                          </a>
                        ))}
                    </>
                  );
                })}
              </Row>
            </div>
          </section>
        </Container>
        <Container fluid={true} className="py-xl-5 px-lg-3">
          <section className="Light-bg my-xl-5 my-lg-5">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <Row>
                {Merchant.map((node, index) => {
                  return (
                    <>
                      <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-12 ps-0 bg-last-section">
                        <img src={node.MerchantImage} alt="bg" />
                      </div>
                      <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 ps-lg-0 pt-xl-5 pt-lg-5 pb-sm-5 ps-sm-5 pt-sm-5 ps-xxl-0 ps-xl-5">
                        <h4 className="fs-40 fw-bold mb-5">
                          {node.MerchantTitle}
                        </h4>
                        <h5 className="bogo-par fw-normal lh-32 mb-5 fw-airlight pe-xl-5 pe-lg-5 pe-md-5 pe-0 me-xl-5 me-lg-5 me-md-5 me-0">
                          {node.MerchantDescription}
                        </h5>
                        <Link
                          to={node.MerchantCTALink}
                          className="fs-18 link-color pb-2"
                        >
                          {node.MerchantCTAText}{" "}
                          <img
                            src="../img/arrow-right.svg"
                            alt="arrow right"
                            className="ms-3"
                          />
                        </Link>
                      </div>
                    </>
                  );
                })}
              </Row>
            </div>
          </section>
        </Container>
      </main>
    </Layout>
  );
}
export const query = graphql`
  query {
    Hero: allHomeJson(filter: { Title: { ne: null } }) {
      nodes {
        AndroidCTALink
        IOSCTALink
        Description
        IOSCTALink
        Image
        SubTitle
        Title
      }
    }
    BogoBenefits: allHomeJson(
      filter: { BogoBenefits: { elemMatch: { BenefitsTitle: { ne: null } } } }
    ) {
      nodes {
        BogoBenefits {
          BenefitsDescription
          BenefitsImage
          BenefitsTitle
          BenefitsCTALink
        }
      }
    }
    BogoServices: allHomeJson {
      nodes {
        BogoServices {
          ServicesImage
          ServicesTitle
          ServicesLink
        }
      }
    }
    Merchant: allHomeJson(filter: { MerchantTitle: { ne: null } }) {
      nodes {
        MerchantCTALink
        MerchantCTAText
        MerchantDescription
        MerchantImage
        MerchantTitle
      }
    }
  }
`;
